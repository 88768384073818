import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateFaq } from "../../actions/faqActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import {CKEditor} from 'ckeditor4-react'
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from 'multiselect-dropdown-react';
import Select from 'react-select';
import { updateAdmin } from '../../actions/superadminaction';
class AdminUpdateModal extends React.Component {
    constructor(props) {
        super(props);
       $("#update-faq-modal").find(".text-danger").hide();
        this.state = {
            id: this.props.record._id,
            email: this.props.record.email,
            properties: this?.props?.record?.properties?.map((el)=> {return el.value}),
            updatedProperties: this?.props?.record?.properties,
            errors: {},
            options:  
                [
                    {
                        label: "Dashboard",
                        value: "dashboard"
                    },
                    {
                        label: "Super Admin",
                        value: "superadmin"
                    },
                    {
                        label: "Users",
                        value: "users"
                    },
                    {
                        label: "User Balance",
                        value: "Userbalance"
                    },
                    {
                        label: "Kyc",
                        value: "kyc"
                    },
                    {
                        label: "Deposit",
                        value: "deposit"
                    },
                    {
                        label: "Withdraw",
                        value: "withdraw"
                    },
                    {
                        label: "Currency",
                        value: "currency"
                    },
                    {
                        label: "Pairmanagement",
                        value: "Pairmanagement"
                    },
                    {
                        label: "Order History",
                        value: "orderhistory"
                    },
                    {
                        label: "Trade History",
                        value: "tradehistory"
                    },
                    {
                        label: "Email Templates",
                        value: "emailtemplates"
                    },
                    {
                        label: "Cms",
                        value: "cms"
                    },
                    {
                        label: "Commission History",
                        value: "commissionhistory"
                    },
                    {
                        label: "Binance Commission History",
                        value: "binancecommissionhistory"
                    },
                    {
                        label: "Faq",
                        value: "faq"
                    },
                    {
                        label: "Contact Us",
                        value: "contactus"
                    },
                    {
                        label: "Newsletter",
                        value: "newsletter"
                    },
                    {
                        label: "Support",
                        value: "support"
                    },
                    {
                        label: "Block List",
                        value: "blocklist"
                    }
                ]
        };
    }
    styles = {
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        multiselectContainer: { 
            backgroundColor: "#03081f",
            borderColor: '#81c8f6',  
          }, 
          searchBox: { 
            border: 'none',
            minHeight: '50px',
            background: '#03081f'
          },     
          inputField: { 
            margin: '5px',
            background: '#03081f'
        },    
        chips: { 
            background: '#02071d'
          },    
        valueContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          padding: '0 6px',
          backgroundColor: "#03081f",
          borderColor: '#81c8f6',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: '1px'
         
        }),
        optionContainer: { 
            border: '2px solid',
            background: '#03081f'
          },

        control: (provided, state) => ({
          ...provided,
          height: '52px',
          borderRadius:10,
          backgroundColor: "#03081f",
          border:'none'
         
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          position: 'absolute',
          right: 0,
          top: 0,
          color:'#fff' 
        }),    
        groupHeading: 
        {
            backgroundColor: "#03081f",
            borderColor: '#81c8f6',
        },
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
          })
      };
    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps_updateModel", nextProps);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                email: nextProps.record.email,
                properties: nextProps?.record?.properties?.map((el)=> {return el.value}),
                updatedProperties: nextProps?.record?.properties
            })
        }
        if (nextProps.errors) {
           
            this.setState({
                errors: nextProps.errors
            });
        }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.Superadminupdate !== undefined
            && nextProps.auth.Superadminupdate.data !== undefined
            && nextProps.auth.Superadminupdate.data.message !== undefined
            && nextProps.auth.Superadminupdate.data.status) {
            $('#update-faq-modal').modal('hide');
            toast(nextProps.auth.Superadminupdate.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.Superadminupdate = "";
        }
    }


    onFaqUpdate = e => {
        e.preventDefault();
        $("#update-faq-modal").find(".text-danger").show();
        const newFaq = {
            email: this.state.email,
            properties: this.state.updatedProperties
        };
        this.props.updateAdmin(newFaq);
    };
    selectorChange = (selectedOption) => {
        console.log(selectedOption, "selectedOption", selectedOption.map((el) => { return el }))
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                "properties": selectedOption.map((el) => { return el.value }),
                "updatedProperties": selectedOption
            })
        } else {
            this.setState({ "properties": [] })
        }
    };
    render() {
        const { errors, properties, options } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-faq-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Admin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqUpdate} id="update-faq">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="faq-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea
                                            value={this.state.email}
                                            id="faq-update-question"
                                            type="text"
                                            error={errors.email}
                                            disabled={true}
                                            className={classnames("form-control", {
                                                invalid: errors.email
                                            })}/>
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="answer">Properties</label>
                                    </div>
                                        <div className="col-md-9">

                                            <Select
                                                value={properties && properties.length > 0 ? options.filter((el, i) => {
                                                    if (properties.includes(el.value)) { return el }
                                                }) : []}
                                                isMulti
                                                onChange={this.selectorChange}
                                                options={options}
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                                classNamePrefix="select"
                                            />

                                        </div>
                                    </div>
                                    

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-faq"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Faq
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

AdminUpdateModal.propTypes = {
    updateAdmin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateAdmin }
)(withRouter(AdminUpdateModal));

