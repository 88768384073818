import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { currencyupdate } from "../../actions/currency";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import keys from "../../actions/config";

const url = keys.baseUrl;

const options = [
  { value: "Crypto", label: "Crypto" },
  { value: "Token", label: "Token" },
  { value: "Fiat", label: "Fiat" },
];

const optionsToken = [
  { value: "ERC_20", label: "ERC_20" },
  { value: "BEP_20", label: "BEP_20" },
  { value: "TRC_20", label: "TRC_20" },
];

class CurrencyUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    $("#update-currency-modal").find(".text-danger").hide();
    console.log(this.props.record, 'tokenTypetokenTypetokenType')
    this.state = {
      hidden: this.props.record.currencySymbol,
      hidden1: this.props.record.currencyName,
      id: this.props.record._id,
      currencyName: this.props.record.currencyName,
      fee: this.props.record.fee,
      minimum: this.props.record.minimum,
      currencySymbol: this.props.record.currencySymbol,
      contractAddress: this.props.record.contractAddress,
      minABI: this.props.record.minABI,
      decimals: this.props.record.decimals,
      type: {
        label: this.props.record.type,
        value: this.props.record.type,
      },
      tokenType: {
        label: this.props.record.tokenType,
        value: this.props.record.tokenType,
      },
      currencyimage: this.props.record.currencyimage,
      errors1: {},
      show: false,
      curimage: null,
      attachment: "",
      imageerror: "",
      curimagesize: 0,
      isEdit: false,
      bankname: "",
      accountno: "",
      holdername: "",
      bankcode: "",
      country: "",
      networkfee: 0
    };
    this.handleChange = this.handleChange.bind(this);
  }

  styles = {
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: "white",
      background: isFocused
        ? '#1d296a'
        : isSelected
          ? '#113853'
          : "#81c8f6",
      zIndex: 1
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: '1px'

    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius: 10,
      backgroundColor: "#03081f",
      border: 'none'

    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#fff'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  componentWillReceiveProps(nextProps) {
    console.log("Token componentWillReceiveProps : ")
    console.log("this.sporor", nextProps);
    if (nextProps.record) {
      console.log("Token componentWillReceiveProps : ", nextProps.record)
      this.setState({
        id: nextProps.record._id,
        currencyName: nextProps.record.currencyName,
        currencySymbol: nextProps.record.currencySymbol,
        fee: nextProps.record.fee,
        minimum: nextProps.record.minimum,
        hidden: nextProps.record.currencySymbol,
        contractAddress: nextProps.record.contractAddress,
        minABI: nextProps.record.minABI,
        decimals: nextProps.record.decimals,
        type: {
          label: nextProps.record.type,
          value: nextProps.record.type,
        },
        tokenType: {
          label: nextProps.record.tokenType,
          value: nextProps.record.tokenType,
        },
        hidden1: nextProps.record.currencyName,
        errors1: "",
        selectedOption: nextProps.record.type,
        currencyimage: nextProps.record.currencyimage,
        bankname: nextProps?.record?.bankDetails?.bankName,
        accountno: nextProps?.record?.bankDetails?.accountNo,
        holdername: nextProps?.record?.bankDetails?.holderName,
        bankcode: nextProps?.record?.bankDetails?.bankcode,
        country: nextProps?.record?.bankDetails?.country,
        networkfee: nextProps?.record?.networkfee
      });
    }
    if (nextProps.errors && this.state.isEdit) {
      console.log("Token componentWillReceiveProps : ", nextProps.errors)
      $("#update-currency-modal").find(".text-danger").show();
      this.setState({
        errors1: nextProps.errors,
      });
    } else {
      this.setState({
        errors1: "",
      });
    }
    // console.log(nextProps)
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyupdate !== undefined &&
      nextProps.auth.currencyupdate.data !== undefined &&
      nextProps.auth.currencyupdate.data.message !== undefined &&
      nextProps.auth.currencyupdate.data.success
    ) {
      $("#update-currency-modal").modal("hide");
      toast(nextProps.auth.currencyupdate.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.currencyupdate = "";
      this.setState({
        errors1: "",
      });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ type: selectedOption });
    // this.setState({currencyType:selectedOption.value})
    //console.log(`Option selected:`, selectedOption );
  };
  handleChangeToken = (optionsTerms) => {
    this.setState({ tokenType: optionsTerms });
  };
  onChange = (e) => {
    let numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    const { id, value } = e.target
    if (["decimals", "fee", "networkfee", "minimum"].includes(id) && !numbers.test(value) && value !== "") {
      return false;
    }
    this.setState({ [e.target.id]: e.target.value });
    $("#update-currency-modal").find(".text-danger").show();
    // if (e.target.id === 'currency-update-currencyName') {
    //     this.setState({ currencyName: e.target.value });
    // }
    // if (e.target.id === 'currency-update-currencySymbol') {
    //     this.setState({ currencySymbol: e.target.value });
    // }
  };
  componentDidMount() {
    console.log("Token componentDidMount : ")
    this.setState({ errors1: {} });
    if (this.props.record.type == "Token") {
      this.setState({ show: true });
    }
  }
  handleChangeefile = (event) => {
    if (event.target.files[0]) {
      var filesize = event.target.files[0].size;
      if (filesize > 1000000) {
        this.setState({ curimagesize: filesize });
        this.setState({ imageerror: "Image size should be less than  1 Mb" });
        toast("Image Size should be less than 1 Mb", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        this.setState({
          curimage: URL.createObjectURL(event.target.files[0]),
          attachment: event.target.files[0],
        });
      }
    }
  };

  onCurrencyUpdate = (e) => {
    e.preventDefault();
    this.setState({ isEdit: true })
    if (this.state.curimagesize < 20000) {
      $("#update-currency-modal").find(".text-danger").show();
      const newFaq = {
        _id: this.state.id,
        currencyName: this.state.currencyName,
        currencySymbol: this.state.currencySymbol,
        fee: this.state.fee,
        minimum: this.state.minimum,
        hidden: this.state.hidden,
        hidden1: this.state.hidden1,
        currencyType: this.state.type.value,
        contractAddress: this.state.contractAddress,
        minABI: this.state.minABI,
        tokenType: this.state.tokenType.label,
        bankname: this?.state?.bankname,
        accountno: this?.state?.accountno,
        holdername: this?.state?.holdername,
        bankcode: this?.state?.bankcode,
        country: this?.state?.country
      };
      const data = new FormData();
      data.append("currencyName", this.state.currencyName);
      data.append("_id", this.state.id);
      data.append("currencySymbol", this.state.currencySymbol);
      data.append("fee", this.state.fee);
      data.append("contractaddress", this.state.contractAddress);
      data.append("currencyType", this.state.type.value);
      data.append("minabi", this.state.minABI);
      data.append("file", this.state.attachment);
      data.append("hidden", this.state.hidden);
      data.append("tokenType", this.state.tokenType.label);
      data.append("hidden1", this.state.hidden1);
      data.append("minimum", this.state.minimum);
      data.append("decimals", this.state.decimals);
      data.append("currencyImage", this.state.currencyimage);
      data.append("bankname", this?.state?.bankname);
      data.append("accountno", this?.state?.accountno);
      data.append("holdername", this?.state?.holdername);
      data.append("bankcode", this?.state?.bankcode);
      data.append("country", this?.state?.country);
      data.append('networkfee', this?.state?.networkfee)
      this.props.currencyupdate(data);
    } else {
      toast("Image Size should be less than 20 Kb", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  render() {
    const { errors1, errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-currency-modal">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Currency</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCurrencyUpdate}
                  id="update-currency"
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="currency-update-id"
                    type="text"
                    className="d-none"
                  />
                  <input
                    onChange={this.onChange}
                    value={this.state.hidden}
                    id="hidden"
                    type="text"
                    className="d-none"
                  />
                  <input
                    onChange={this.onChange}
                    value={this.state.hidden1}
                    id="hidden1"
                    type="text"
                    className="d-none"
                  />

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Currency Type</label>
                    </div>
                    <div className="col-md-9">
                      {this.state.type ? (
                        <Select
                          value={this.state.type}
                          defaultValue={{
                            label: this.state.type.label,
                            value: this.state.type.label,
                          }}
                          onChange={this.handleChange}
                          options={options}
                          styles={this.styles} className="border_blue_select"
                        />
                      ) : (
                        ""
                      )}
                      <span className="text-danger">{errors1.type}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Currency Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.currencyName}
                        id="currencyName"
                        type="text"
                        error={errors1.currencyName}
                        className={classnames("form-control", {
                          invalid: errors1.currencyName,
                        })}
                      />
                      <span className="text-danger">
                        {errors1.currencyName}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencySymbol">Currency Symbol</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        readOnly={true}
                        onChange={this.onChange}
                        value={this.state.currencySymbol}
                        error={errors1.currencySymbol}
                        id="currencySymbol"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors1.currencySymbol,
                        })}
                      />
                      <span className="text-danger">
                        {errors1.currencySymbol}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencySymbol">Decimals</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.decimals}
                        error={errors1.decimals}
                        id="decimals"
                        type="number"
                        min="0"
                        className={classnames("form-control", {
                          invalid: errors1.decimals,
                        })}
                      />
                      <span className="text-danger">
                        {errors1.decimals}
                      </span>
                    </div>
                  </div>

                  {this.state.type.value == "Token" ? (
                    <div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="currencyName">Contract Address</label>
                        </div>
                        <div className="col-md-9">
                          <input
                            onChange={this.onChange}
                            value={this.state.contractAddress}
                            error={errors1.contractAddress}
                            id="contractAddress"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors1.contractAddress,
                            })}
                          />
                          <span className="text-danger">
                            {errors1.contractAddress}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="currencyName">Token Type</label>
                        </div>
                        <div className="col-md-9">
                          <Select
                            value={this.state.tokenType}
                            defaultValue={{
                              label: this.state.tokenType.label,
                              value: this.state.tokenType.label,
                            }}
                            onChange={this.handleChangeToken}
                            options={optionsToken}
                            styles={this.styles} className="border_blue_select"
                            label="Single select"
                          />
                          <span className="text-danger">{errors1.tokenType}</span>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="currencyName">Min ABI</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            onChange={this.onChange}
                            value={this.state.minABI}
                            error={errors1.minABI}
                            id="minABI"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors1.minABI,
                            })}
                          />
                          <span className="text-danger">{errors1.minABI}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="fee">Withdrawal Fee (%)</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={parseFloat(this.state.fee).toFixed(8)}
                        error={errors1.fee}
                        id="fee"
                        type="number"
                        min="0"
                        className={classnames("form-control", {
                          invalid: errors1.fee,
                        })}
                      />
                      <span className="text-danger">{errors1.fee}</span>
                    </div>
                  </div>

                  {this.state.type.value !== 'Fiat' ?
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="fee">Network Fee</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.networkfee}
                          error={errors?.networkfee}
                          id="networkfee"
                          type="text"
                          className={classnames("form-control", {
                            invalid: errors?.networkfee,
                          })}
                        />
                        <span className="text-danger">{errors?.networkfee}</span>
                      </div>
                    </div>
                    : ''

                  }

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Minimum Withdrawal</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={parseFloat(this.state.minimum).toFixed(8)}
                        error={errors1.minimum}
                        id="minimum"
                        type="number"
                        min="0"
                        className={classnames("form-control", {
                          invalid: errors1.minimum,
                        })}
                      />
                      <span className="text-danger">{errors1.minimum}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">Currency Icon</label>
                    </div>
                    <div className="col-md-9">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={this.handleChangeefile}
                          name="file1"
                          id="exampleInputFile"
                          aria-describedby="fileHelp"
                        />
                        Choose File
                      </label>
                      <span className="text-danger">
                        {this.state.imageerror}
                      </span>

                      {this.state.curimage != null ? (
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.curimage}
                        />
                      ) : (
                        <img
                          className="img-fluid proofThumb"
                          src={url + "currency/" + this.state.currencyimage}
                        />
                      )}
                    </div>
                  </div>

                  {this?.state?.type?.value === "Fiat" ? <>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Bank Name</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.bankname}
                          id="bankname"
                          type="text"
                          error={errors1?.bankname}
                          className={classnames("form-control", {
                            invalid: errors1?.bankname,
                          })}
                        />
                        <span className="text-danger">{errors1?.bankname}</span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Account Number</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.accountno}
                          id="accountno"
                          type="text"
                          error={errors1.accountno}
                          className={classnames("form-control", {
                            invalid: errors1.accountno,
                          })}
                        />
                        <span className="text-danger">{errors1.accountno}</span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Holder Name</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.holdername}
                          id="holdername"
                          type="text"
                          error={errors1.holdername}
                          className={classnames("form-control", {
                            invalid: errors1.holdername,
                          })}
                        />
                        <span className="text-danger">{errors1.holdername}</span>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Bank Code</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.bankcode}
                          id="bankcode"
                          type="text"
                          error={errors1.bankcode}
                          className={classnames("form-control", {
                            invalid: errors1.bankcode,
                          })}
                        />
                        <span className="text-danger">{errors1.bankcode}</span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Country</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          onChange={this.onChange}
                          value={this.state.country}
                          id="country"
                          type="text"
                          error={errors1.country}
                          className={classnames("form-control", {
                            invalid: errors1.country,
                          })}
                        />
                        <span className="text-danger">{errors1.country}</span>
                      </div>
                    </div>
                  </> : <></>}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="update-currency"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update Currency
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CurrencyUpdateModal.propTypes = {
  currencyupdate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { currencyupdate })(
  withRouter(CurrencyUpdateModal)
);
