import axios from "axios";
import {
    GET_ERRORS,
    FAQ_ADD,
    FAQ_UPDATE,
    SUPER_ADMIN,
    SUPER_ADMINUPDATE
} from "./types";
import keys from "./config";
const url = keys.baseUrl;

export const addAdmin = (data) => dispatch => {
    axios
        .post(url + "api/addAdmin", data)
        .then(res =>
            dispatch({
                type: SUPER_ADMIN,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateAdmin = (data) => dispatch => {
    console.log("updateAdmin_data", data)
    axios
        .post(url + "api/updateAdmin", data)
        .then(res =>
            dispatch({
                type: SUPER_ADMINUPDATE,
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
