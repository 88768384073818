// import package
import isEmpty from 'is-empty';
import moment from 'moment';
export const momentFormat = (dateTime,format='DD-MM-YYYY HH:mm') => {
    try{
        if (!isEmpty(dateTime)) {
            let newDateTime = new Date(dateTime);
            return moment(newDateTime).format(format)
        }
        return ''
    } catch(err){
        return ''
    }
}