import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { Modal, Button } from 'react-bootstrap/';
import ConfirmationModal from "./ConfirmationModal";
import RecordViewModal from "../partials/RecordViewModal";

import Select from 'react-select';
import isEmpty from "is-empty";
import {momentFormat} from '../../lib/DateFormat'
const url = keys.baseUrl;
const options = [
    { 'value': "Activated", 'label': 'Activated' },
    { 'value': "blocked", 'label': 'blocked' },
    { 'value': "suspended", 'label': 'suspended' },
    { 'value': "closed", 'label': 'closed' }]
class Users extends Component {

    constructor(props) {
        super(props);


        this.columns = [
            {
                key: "date",
                text: "Created date",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record?.date, "record?.date", record.email)
                    const dateReplace = record?.date?.replace("T", "  ")
                    const split = dateReplace?.split(' ');
                    const join = split[0] + " " + "T" + split[2];
                    return (
                        join
                    )
                }
            },
            {
                key: "firstname",
                text: "First Name",
                className: "email",
                align: "left",
                sortable: false,
                cell: record => {
                    if (record?.kycType === 'individual') {
                        return record?.verifiedstatus === "Not verified" ? '-' : `${record?.personalInformation?.firstname}`
                    }
                    else if (record?.kycType === "entity") {
                        console.log(record, "record__entity")
                        return record?.verifiedstatus === "Not verified" ? '-' : `${record?.companyRegistrationDetails?.companyname}`
                    }
                    else {
                        return '-'
                    }

                }
            },
            {
                key: "lastname",
                text: "Last Name",
                className: "email",
                align: "left",
                sortable: false,
                cell: record => {
                    if (record?.kycType === 'individual') {
                        return record?.verifiedstatus === "Not verified" ? '-' : `${record?.personalInformation?.lastname}`
                    }
                    else if (record?.kycType === "entity") {
                        return "-"
                    }
                    else {
                        return '-'
                    }

                }

            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record, 'recordssssss');
                    const checkin = record.active;
                    const kycStatus = record.verifiedstatus
                    const sumsubStatus = record.sumsubStatus == 'completed' && record.reviewRejectType == "COMPLETED" ? 'completed' : 'notcompleted'
                    const kyc = kycStatus == 'FINISHED' && sumsubStatus == 'completed' ? 'completed' : 'notcompleted'
                    console.log(checkin, 'checkineeed');
                    if (checkin == "Activated" && kyc != 'completed') {
                        var lockVal = 'Confirmed';
                    }
                    else if (checkin == "Activated" && kyc == 'completed') {
                        var lockVal = 'Active';
                    }
                    else if (checkin == 'blocked') {
                        var lockVal = 'Blocked';
                    }
                    else if (checkin == 'suspended') {
                        var lockVal = 'Suspended';
                    } else if (checkin == 'closed') {
                        var lockVal = 'Closed';
                    }
                    else {
                        var lockVal = 'Inactive';
                    }

                    return (
                        lockVal
                    );
                }
            },
            {
                key: "status",
                text: "Kyc Status",
                className: "status",
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record, 'recordssssss');
                    // const checkin = record.active;
                    const kycStatus = record.verifiedstatus
                    const sumsubStatus = record.sumsubStatus == 'completed' && record.reviewRejectType == "COMPLETED" ? 'completed' : 'notcompleted'
                    const kyc = kycStatus == 'FINISHED' && sumsubStatus == 'completed' ? 'completed' : 'notcompleted'
                    // console.log(checkin, 'checkineeed');
                    if (kyc == 'completed') {
                        var lockVal = 'Accepted';
                    }
                    else if (kycStatus == 'PENDING') {
                        var lockVal = 'Pending';
                    }
                    else if (kycStatus == 'REJECTED') {
                        var lockVal = 'Pending';
                    }
                    else {
                        var lockVal = kycStatus;
                    }


                    return (
                        lockVal
                    );
                }
            },
            {
                key: "binSubAcctId",
                text: "Sub Account no",
                className: "status",
                align: "left",
                // sortable: false
            },
            {
                key: "binSubAcctEmail",
                text: "Sub Account Email",
                className: "status",
                align: "left",
                // sortable: false
            },
            {
                key: "statusComment",
                text: "Comment",
                className: "status",
                align: "left",
                // sortable: false
            },
            {
                key: "Time",
                text: "Updated Time",
                className: "status",
                sortable: false,
                cell: record => {
                    console.log(record,"record.statusTime")
                    let Time = record.statusTime
                    return (
                        <>
                         <p>
                            {momentFormat(Time,"DD-MM-YYYY HH:mm")}
                         </p>
                    </>
                     );
                }
            },
            {
                key: "records",
                text: "History",
                className: "records",
                width: 150,
                align: "left",
                sortable: false,
                cell: record => {
                 

                    return (
                        <Fragment>
                         
                           <button
                                    data-toggle="modal"
                                    data-target="#record-view-modal"
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => { this.viewHistoryRecord(record) }}
                                >
                                  View
                                </button>

                         


                        </Fragment>
                    );
                }
            },
            
            //  {
            //     key: "moderator",
            //     text: "Moderator status",
            //     className: "moderator",
            //     align: "left",
            //     sortable: false,
            //     cell: record => {

            //         const checkin = record.moderator;
            //         console.log(checkin,'checkineeed');
            //         if(checkin=='1'){
            //             var lockVal = 'Active';
            //         } else{
            //             var lockVal = 'Deactive';
            //         }

            //         return (
            //             lockVal
            //         );
            //     }
            // },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record, 'recordssssss');
                    const checkin = record.active;
                    console.log(checkin, 'checkineeed');
                    if (checkin == 'Activated') {
                        var lockVal = 'fa fa-unlock';
                    } else {
                        var lockVal = 'fa fa-lock';
                    }

                    return (
                        <Fragment>
                            {/* <button
                                className="btn btn-danger btn-sm mr-2"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                            <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => {
                                    this.setState({ updateModal: true })
                                    this.setState({ updateRecord: record })
                                    this.setState({ "comment": record.statusComment, 'status': record.active })
                                    // this.changestatus(record)
                                }}>
                                {/* <i className={lockVal}></i> */}
                                <i className='fa fa-edit'></i>
                            </button>
                            {record.google == "Enabled" &&
                                <button
                                    className="btn btn-danger btn-sm mr-2"
                                    onClick={() => this.changeDisabled(record)}>
                                    Disabled
                                </button>
                            }
                        

                             {/* <button title="block chat"
                               className="btn btn-primary btn-sm mr-1"
                                onClick={() => this.chatblock(record)}>
                                <i class="fa fa-user-times"></i>
                            </button> */}


                        </Fragment>
                    );
                }
            },
           
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Users",
            no_data_text: 'No user found!',
            sort: { column: "Created date", order: "desc" },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            // defaultSortAsc: true,
        };

        this.state = {
            totalCount: 0,
            currentRecord: {
                records: [],
                id: '',
                name: '',
                email: '',
                status: '',
                password: '',
                password2: '',
                lockVal: '',
                blockinguser: '',
                bonususer: '',
                blocking_time: '',
                bonus_amount: '',
                modalshow: false,
                modalshow1: false,
                responsive: true,
                DeletUser: false,
                DeletRecord: {},
                updateModal: false
            },
            limit: 10,
            page: 1,
            searchState: "",
            status: "",
            comment: '',
            updateRecord: {},
            errors: {}
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        console.log(this.state, 'statezzzzzz');
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const data = {
            limit: this.state.limit,
            page: this.state.page,
            search: this.state.searchState,
            type: 'user'
        }
        axios
            .post(url + "api/user-get", data)
            .then(res => {
                this.setState({ records: res.data.user, totalCount: res.data.count })
                console.log(res, 'reszzzzhhhhhh');
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });

    }

    viewHistoryRecord(record) {
        console.log('history record data', record)
        this.setState({ currentHistoryRecord : record })
    }
    changeDisabled(record) {
        axios
            .post(url + "api/user-disabled", { _id: record._id })
            .then(res => {
                console.log(res.data, 'message')
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.getData()
                }
            })
    }
    deleteRecord(record) {
        // axios
        //     .post(url+"api/user-delete", {_id: record._id})
        //     .then(res => {
        //         if (res.status === 200) {
        //            toast(res.data.message, {
        //                position: toast.POSITION.TOP_CENTER,
        //            })
        //            this.getData()
        //         }
        //     })
        //     .catch();
        // this.getData();
        this.setState({ "DeletRecord": record, "DeletUser": true })
    }
    handleChange = selectedOption => {
        console.log(selectedOption,'selectedOption')
        this.setState({ status: selectedOption.value });
        console.log(`Option selected:`, selectedOption);
    };
    blockingsubmission = () => {
        if (this.state.blockinguser == '' || this.state.blocking_time == '') {
            alert("Enter blockind time");
            return false;
        }
        axios
            .post(url + "api/blockuser", { _id: this.state.blockinguser, blocking_time: this.state.blocking_time })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
        this.modalclose();
    }
    styles = {
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            padding: '0 6px',
            backgroundColor: "#03081f",
            borderColor: '#81c8f6',
            borderRadius: 10,
            borderStyle: 'solid',
            borderWidth: '1px'

        }),
        control: (provided, state) => ({
            ...provided,
            height: '52px',
            borderRadius: 10,
            backgroundColor: "#03081f",
            border: 'none'

        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#fff'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };

    bonussubmission = () => {
        if (this.state.bonususer == '' || this.state.bonus_amount == '') {
            alert("Enter blockind time");
            return false;
        }
        axios
            .post(url + "api/addbonus", { _id: this.state.bonususer, bonus_amount: this.state.bonus_amount })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
        this.modalclose1();
    }

    chatblock(record) {
        this.setState({ modalshow: true, blockinguser: record._id })
    };



    onChange = (e) => {
        this.setState({errors:{}})
        this.setState({ [e.target.id]: e.target.value });
    };

    modalclose = () => {
        this.setState({ modalshow: false });
    }

    modalclose1 = () => {
        this.setState({ modalshow1: false });
    }


    changestatus(record) {
        axios
            .post(url + "api/user-changestatus", { _id: record._id })
            .then(res => {
                console.log(res, 'reschange');
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.getData();
                }
            })
            .catch();
        // this.getData();
    }

    changemoderator(record) {
        axios
            .post(url + "api/user-changemoderator", { _id: record._id })
            .then(res => {
                console.log(res, 'reschange');
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    async pageChange(pageData) {
        console.log("OnPageChange_OnPageChange", pageData);
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            type: 'user',
            search: pageData.filter_value
        }
        this.setState({ limit: pageData.page_size, page: pageData.page_number, searchState: pageData.filter_value })
        const getData = await axios.post(url + "api/user-get", data);
        this.setState({ records: getData.data.user, totalCount: getData.data.count });

    }

    validate = (data) => {
        try {
            let errors = this.state.errors
            if (isEmpty(data.status)) {
                errors = { ...errors, ['status']: 'Status is required' }
            }
            if (isEmpty(data.comment)) {
                errors = { ...errors, ['comment']: 'comment is required' }
            }
            this.setState({ errors: errors })
            if (!isEmpty(errors)) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.log(err, "validate__err")
        }
    }
    handleUpdateStatus = async () => {
        try {

            let data = {
                _id: this.state.updateRecord._id,
                status: this.state.status,
                comment: this.state.comment
            }
            let isvalid = this.validate(data)
            if (isvalid) {
                return false
            }
            axios
                .post(url + "api/user-changestatus", data)
                .then(res => {
                    console.log(res, 'reschange');
                    if (res.status === 200) {
                        toast(res.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                        })
                        this.setState({ updateModal: false, errors: {}, updateRecord: {} })
                        this.getData();
                    }
                })
                .catch();

        } catch (err) {
            console.log(err, "handleUpdateStatus__err")
        }
    }
    CloseUpdateModal = () => {
        try {
            this.setState({ updateModal: false, errors: {}, updateRecord: {} })
        } catch (err) {
            console.log(err, "handleUpdateModal__err")
        }
    }
    render() {
        const { selectedOption } = this.state.status;
        const { errors } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    {this.state.DeletUser ? <ConfirmationModal record={this.state.DeletRecord} getData={this.getData} onDismiss={() => { this.setState({ "DeletUser": false }) }} /> : ""}
                    <UserAddModal />
                    <UserUpdateModal record={this.state.currentRecord} />
                    <RecordViewModal record={this.state.currentHistoryRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-outline-primary float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> add</button> */}
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary">User management </h3>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    responsive={true}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.totalCount}
                                    onChange={this.pageChange.bind(this)}
                                    className="table table-bordered table-striped user_dt_table user_mangt_table"
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
                <Modal show={this.state.updateModal} onHide={this.CloseUpdateModal} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Update user status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="form-group">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="first_currency">user status</label>
                                </div>
                                <div className="col-md-9">
                                    <Select
                                        // menuIsOpen={true} 
                                        value={options.map((val) => {
                                            if (val.value == this.state.status) {
                                                return val
                                            }
                                        })}
                                        onChange={this.handleChange}
                                        options={options}
                                        styles={this.styles}
                                        className="border_blue_select"
                                    />
                                    <span className="text-danger">{errors && errors.status}</span>
                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="first_currency">Comment</label>
                                </div>
                                <div className="col-md-9">
                                    <textarea
                                        onChange={this.onChange}
                                        value={this.state.comment}
                                        error={errors.comment}
                                        id="comment"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.comment
                                        })}
                                    />
                                    <span className="text-danger">{errors.comment}</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.CloseUpdateModal}>
                            Cancel
                        </Button>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.handleUpdateStatus}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalshow} onHide={this.modalclose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Block chat</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="checkbox pt-2"><label>Blocking time (in hours)</label></div>
                                </div>
                                <div className="col-md-8">
                                    <div className="def-number-input number-input safari_only">
                                        <input className="quantity" min="0" name="blocking_time" id="blocking_time" onChange={this.onChange} type="number" value={this.state.blocking_time} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.modalclose}>
                            Cancel
                        </Button>
                        {(this.state.blockinguser == '' || this.state.blocking_time == '') ?
                            <Button disabled="disabled" variant="primary btnDefaultNew" onClick={this.blockingsubmission}>
                                Confirm
                            </Button>
                            : <Button variant="primary btnDefaultNew" onClick={this.blockingsubmission}>
                                Confirm
                            </Button>}

                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalshow1} onHide={this.modalclose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Bonus</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="checkbox pt-2"><label>Bonus amount in BTC</label></div>
                                </div>
                                <div className="col-md-8">
                                    <div className="def-number-input number-input safari_only">
                                        <input className="quantity" min="0" name="bonus_amount" id="bonus_amount" onChange={this.onChange} type="number" value={this.state.bonus_amount} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.modalclose}>
                            Cancel
                        </Button>
                        {(this.state.bonususer == '') ?
                            <Button disabled="disabled" variant="primary btnDefaultNew" onClick={this.bonussubmission}>
                                Confirm
                            </Button>
                            : <Button variant="primary btnDefaultNew" onClick={this.bonussubmission}>
                                Confirm
                            </Button>}

                    </Modal.Footer>
                </Modal>
            </div>

        );
    }

}

Users.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Users);
