import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal, Button } from 'react-bootstrap/';
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import keys, { imageUrl } from "../../actions/config";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DepositExportcsv } from "../../actions/userActions";
import { truncate } from "fs";
import { Approveuserasset, Rejectuserasset, AddDeposit } from "../../actions/assetactions";

const url = keys.baseUrl;

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "created_date",
                text: "Created date",
                className: "created_date",
                align: "left",
                sortable: false
            },
            {
                key: "toaddress",
                text: "To Address",
                className: "toaddress",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "currency",
                text: "Coin",
                className: "currency",
                align: "left",
                sortable: false
            },
            {
                key: "user_id",
                text: "User email",
                className: "user_id",
                align: "left",
                sortable: false,
                width: 200,
            },

            {
                key: "amount",
                text: "Transfer Amount",
                className: "amount",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false
            },
            {
                // key: ()=><><button className="btn btn-primary" type="button">next</button></> , //"txid", {record?.txid ? record?.txid : <button onClick={this.handleShow} className="btn btn-primary" type="button">next</button>}
                // cell: record => record?.txid ? record?.txid : <button disabled={record?.status == "Approved" || record?.status == "Rejected" ? true : false} onClick={() => this.handleShow(record)} className="btn btn-primary" type="button">View</button>,
                cell: record => record?.txid ? record?.txid : "",
                // <button onClick={() => this.handleShow(record)} className="btn btn-primary" type="button">View</button>,
                text: "Transaction Id",
                className: "txid",
                align: "left",
                sortable: false
            },
            {
                // key: ()=><><button className="btn btn-primary" type="button">next</button></> , //"txid", {record?.txid ? record?.txid : <button onClick={this.handleShow} className="btn btn-primary" type="button">next</button>}
                // cell: record => record?.txid ? record?.txid : <button disabled={record?.status == "Approved" || record?.status == "Rejected" ? true : false} onClick={() => this.handleShow(record)} className="btn btn-primary" type="button">View</button>,
                cell: record => record?.transferType == 'BANK-TRANSFER' ? <button onClick={() => this.handleShow(record)} className="btn btn-primary" type="button">View</button> : "",
                text: "Action",
                className: "txid",
                align: "left",
                sortable: false
            },

        ];



        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Withdraw",
            no_data_text: 'No Records found!',
            sort: { column: "Created date", order: "desc" },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search by coin / user email",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            amt: 0,
            imgsrc: "",
            show: false,
            records: [],
            showDetails: false,
            id: '',
            status: '',
            transferamount: '',
            cryptoType: '',
            userId: '',
            receiveraddress: '',
            tagid: '',
            errors: '',
            startDate: '',
            endDate: '',
            dateStatus: '',
            totalCount: 0,
            singlerecord: {},
            limit: 10,
            page: 1,
            AddShow: false,
            AddDeposit: {
                userId: "",
                currency: "",
                amount: "",
                transactionNo: "",
                proof: ""
            },
            emailoption: [],
            currencyoptions: [],
            proofImage: "",
            proofimageerror: ""

        };
        this.getData = this.getData.bind(this);
    }
    styles = {
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            padding: '0 6px',
            backgroundColor: "#03081f",
            borderColor: '#81c8f6',
            borderRadius: 10,
            borderStyle: 'solid',
            borderWidth: '1px'

        }),
        control: (provided, state) => ({
            ...provided,
            height: '52px',
            borderRadius: 10,
            backgroundColor: "#03081f",
            border: 'none'

        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#fff'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };
    componentDidMount() {
        this.getData()
        this.getOption()
    };
    showDetails = (record) => {
        this.setState({ receiveraddress: record.receiveraddress.address })
        this.setState({ tagid: record.receiveraddress.tagid })
        this.setState({ id: record._id })
        this.setState({ cryptoType: record.cryptoType })
        this.setState({ transferamount: record.transferamount })
        this.setState({ status: record.status })
        this.setState({ showDetails: true })
    }
    confirmSubmit = () => {
        var id = this.state.id;
        var passVal = { id: id, status: "Confirmed" };
        axios
            .post(url + "api/updatewithdraw", passVal)
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.setState({ showDetails: false });
                this.getData();
            })
            .catch()
    }
    rejectSubmit = () => {
        var id = this.state.id;
        var passVal = { id: id, status: "Rejected" };
        axios
            .post(url + "api/updatewithdraw", passVal)
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.setState({ showDetails: false });
                this.getData();
            })
            .catch()
    }

    handleClosedetails = (record) => {
        this.setState({ showDetails: false })
    }
    getData() {
        const data = {
            limit: this.state.limit,
            page: this.state.page
        }
        axios
            .post(url + "api/deposit-data", data)
            .then(res => {
                console.log('deposit-data', res);
                this.setState({ records: res.data.data, totalCount: res.data.count })
            })
            .catch()
    }

    getOption() {
        try {
            axios
                .get(url + "api/getoption")
                .then(res => {
                    console.log('deposit-data', res);
                    if (res.data) {
                        if (res.data.userData.length > 0) {
                            let userData = res.data.userData
                            var userarray = [];
                            userData.map((item, i) => {
                                const name = item.email;
                                const value = item._id;
                                const obj = { 'value': value, 'label': name };
                                userarray.push(obj);
                            });
                            this.setState({ emailoption: userarray });
                        }
                        if (res.data.currencyData.length > 0) {
                            let currencyData = res.data.currencyData
                            var currencyarray = [];
                            currencyData.map((item, i) => {
                                const name = item.currencySymbol;
                                const value = item._id;
                                const obj = { 'value': name, 'label': name };
                                currencyarray.push(obj);
                            });
                            this.setState({ currencyoptions: currencyarray });
                        }
                    }
                })
                .catch()
        } catch (err) {
            console.log(err, " getOption()")
        }
    }


    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        this.setState({ limit: pageData.page_size, page: pageData.page_number })
        const getData = await axios.post(url + "api/deposit-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    async sortByDate() {
        if (this.state.startDate != "" || this.state.endDate != "") {
            const formData = new FormData();
            formData.append("from", this.state.startDate);
            formData.append("to", this.state.endDate);
            formData.append("type", "search");
            const data = await axios.post(url + "api/deposit-dateFilter", formData);
            console.log("data_data", data.data);
            this.setState({ records: data.data })
        } else {
            console.log("Select date first");
        }
    }

    async downloadCSV() {
        let data = {
            export: 'csv',
            from: this.state.startDate,
            to: this.state.endDate,
        }
        const download = await DepositExportcsv(data);
        console.log("downloadCSV_downloadCSV", download);
    }

    // const [show, setShow] = useState(false);

    handleClose = () => this.setState({ show: false })
    handleShow = (record) => { console.log("records in show", record); this.setState({ singlerecord: record, amt: record?.amount, imgsrc: record?.attachment }); this.setState({ show: true }) }
    //  handleShow = () => setShow(true);
    handleapprove = () => {
        console.log("handleappreove", this.state.singlerecord);
        var payload = {
            id: this.state.singlerecord?.id,
            userid: this.state.singlerecord.user?._id,
            currency: this.state?.singlerecord?.currency,
            balance: this.state?.singlerecord?.amount,
        }
        this.props.Approveuserasset(payload);
        // this.handleClose()
    }


    componentWillReceiveProps(nextProps) {
        console.log(nextProps, 'Deposite')
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth.Assetupdate) {
            this.handleClose()
            this.getData()

            nextProps.auth.newuser = undefined;
        }
        if (nextProps.auth.Assetupdate) {
            this.handleClose()
            this.getData()
            toast(nextProps.auth.Assetupdate.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.newuser = undefined;
        }
    }


    handlereject = () => {
        var payload = {
            id: this.state.singlerecord?.id,
        }
        this.props.Rejectuserasset(payload);
    }

    addRecord = () => {
        try {
            this.setState({ AddShow: true })
        } catch (err) {
            console.log(err, "addRecord__err")
        }
    }

    closeAdd = () => {
        try {
            this.setState({ AddShow: false })
        } catch (err) {
            console.log(err, "addRecord__err")
        }
    }

    handleChangeDeposit = (e) => {
        try {
            const { id, value } = e.target
            let data = this.state.AddDeposit
            data = { ...data, ...{ [id]: value } }
            this.setState({ AddDeposit: data })
        } catch (err) {
            console.log(err, "handleChangeDeposit__err")
        }
    }

    handleSelectemail = (selectedOption) => {
        try {
            let data = this.state.AddDeposit
            data.userId = selectedOption.value
            this.setState({ AddDeposit: data })
        } catch (err) {
            console.log(err, "handleSelectemail__err")
        }
    }

    handleSelectcurrency = (selectedOption) => {
        try {
            let data = this.state.AddDeposit
            data.currency = selectedOption.value
            this.setState({ AddDeposit: data })
        } catch (err) {
            console.log(err, "handleSelectemail__err")
        }
    }

    handleSelectProof = (event) => {
        try {
            this.setState({ proofimageerror: "" })
            console.log(event.target.files[0], "image")
            if (event.target.files[0]) {
                var filesize = event.target.files[0].size
                if (filesize > 100000) {
                    console.log("filesize", filesize)
                    // this.setState({ curimagesize: filesize })
                    this.setState({ proofimageerror: "Image size should be less than 100 Kb" })
                    // toast("Image Size should be less than 20 Kb", {
                    //     position: toast.POSITION.TOP_CENTER,
                    // });
                }
                else {
                    console.log("filesize2", filesize)
                    let data = this.state.AddDeposit
                    data.proof = event.target.files[0]
                    this.setState({ "AddDeposit": data, proofImage: URL.createObjectURL(event.target.files[0]), })
                }
            }
        } catch (err) {
            console.logI(err, "handleSelectProof")
        }
    }

    handleSubmitDeposit = async () => {
        try {
            let formaData = new FormData()
            let data = this.state.AddDeposit
            formaData.append("userId", data.userId)
            formaData.append("currency", data.currency)
            formaData.append("amount", data.amount)
            formaData.append("proof", data.proof)
            formaData.append("transactionNo", data.transactionNo)
            let res = await AddDeposit(formaData)
            toast(res, {
                position: toast.POSITION.TOP_CENTER,
            });
            data ={
                userId:"",
                currency:"",
                amount:"",
                proof:""
            }
            this.setState({AddShow:false,AddDeposit:data})
        } catch (err) {
            console.logI(err, "handleSubmitDeposit__err")
        }
    }
    render() {
        const { transactionNo, amount, currency, userId, proof } = this.state.AddDeposit
        const { currencyoptions, emailoption } = this.state
        console.log("this.state.AddDeposit", this.state.AddDeposit)
        return (
            <div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                        {/* siva for pdf */}
                        {
                            this?.state?.singlerecord?.attachment?.includes('.pdf') ? 
                            (
                            <a href={imageUrl + "bank_transfer/" + this.state.singlerecord.attachment} target="_blank">View File</a>)
                                :
                                ( this?.state?.singlerecord?.attachment &&
                                    <img
                                className="img-fluid"
                                src={imageUrl + "bank_transfer/" + this.state.singlerecord.attachment} //"https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                alt="img" />
                                )
                        }
                            

                        </div>
                        <div class="mt-3 mb-3">
                            <label>Amount</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" value={this.state.amt} aria-describedby="text" />
                        </div>
                        {this.state.singlerecord.status == "Approved" ? ""
                            :
                            <div className="d-flex justify-content-between">
                                <button type="button" class="btn btn-danger" onClick={this.handlereject}>Reject</button>
                                <button type="button" class="btn btn-success" onClick={this.handleapprove}>Approve</button>
                            </div>
                        }


                    </Modal.Body>

                </Modal>

                <Modal show={this.state.showDetails} onHide={this.handleClosedetails} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popUpSpace">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">To address</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.receiveraddress}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Tag id/Memo</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.tagid}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transfer Coin</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.cryptoType}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Transfer Amount</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.transferamount}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">Status</label>
                                </div>
                                <div className="col-md-9">
                                    {this.state.status}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger btnDefaultNewBlue" onClick={this.rejectSubmit}>
                            Reject
                        </Button>
                        <Button onClick={this.confirmSubmit} variant="success btnDefaultNew" >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.AddShow} onHide={this.closeAdd} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Deposit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {/* <div className="modal-body"> */}
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencyName">Email</label>
                                </div>
                                <div className="col-md-9">
                                    <Select
                                        // value={selectedTerms}
                                        onChange={this.handleSelectemail}
                                        options={emailoption}
                                        label="Single select"
                                        styles={this.styles}
                                        className="border_blue_select"
                                    />
                                    {/* <span className="text-danger">{errors.userId}</span> */}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencyName">Deposit Currency</label>
                                </div>
                                <div className="col-md-9">
                                    <Select
                                        // value={selectedTerms}
                                        onChange={this.handleSelectcurrency}
                                        options={currencyoptions}
                                        label="Single select"
                                        styles={this.styles}
                                        className="border_blue_select"
                                    />
                                    {/* <span className="text-danger">{errors.currency}</span> */}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencySymbol">Enter Amount</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChangeDeposit}
                                        value={amount}
                                        id="amount"
                                        type="number"
                                        min='0'
                                        className={classnames("form-control")}
                                    />
                                    {/* <span className="text-danger">
                                            {errors.amount}
                                        </span> */}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencySymbol">Transaction no</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChangeDeposit}
                                        value={transactionNo}
                                        id="transactionNo"
                                        type="text"
                                        min='0'
                                        className={classnames("form-control")}
                                    />
                                    {/* <span className="text-danger">
                                            {errors.transactionNo}
                                        </span> */}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="minimum">Bank transfer receipt/ Proof of the transaction</label>
                                </div>
                                <div className="col-md-9">
                                    <label class="custom-file-upload">
                                        <input
                                            type="file"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            onChange={this.handleSelectProof}
                                            name="file1"
                                            id="exampleInputFile"
                                            aria-describedby="fileHelp"
                                        />
                                        Choose File
                                    </label>
                                    <span className="text-danger">{this.state.proofimageerror && this.state.proofimageerror}</span>
                                    <img
                                        className="img-fluid proofThumb"
                                        src={this.state.proofImage}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger btnDefaultNewBlue" onClick={this.closeAdd}>
                            close
                        </Button>
                        <Button onClick={this.handleSubmitDeposit} variant="success btnDefaultNew" >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-0 mr-2 btn_pos_right"><FontAwesomeIcon icon={faPlus} /> Add Deposit</button>
                            <h3 className="mt-2 text-secondary">Deposit List</h3>
                            <div className="mt-4">
                                <button onClick={() => { this.downloadCSV() }} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                                {/* <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                            <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(XLS)</button> */}
                            </div>
                            <div>
                                <p className="filter_date_text">Filter based on date</p>
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ startDate: date }) }}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={(date) => { this.setState({ endDate: date }); }}
                                            minDate={this.state.startDate}
                                            maxDate={new Date()}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <button className="btn btn-light mt_seact_date" onClick={() => { this.sortByDate() }}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    // responsive={true}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.totalCount}
                                    onChange={this.pageChange.bind(this)}
                                    className="table table-bordered table-striped deposit_dt_table"
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Deposit.propTypes = {
    auth: PropTypes.object.isRequired,
    Approveuserasset: PropTypes.object.isRequired,
    Rejectuserasset: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps,
    {
        Approveuserasset,
        Rejectuserasset
    }
)(Deposit);
