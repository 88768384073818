import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OrderHistoryExportcsv } from "../../actions/userActions";
const url = keys.baseUrl;
class tradehistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount:0,
            records: [],
            userlist:[],
            startDate: "",
            endDate: "",
            limit :10,
            page : 1,
            search:""
        };
        this.columns = [
            {
                key: "pairName",
                text: "Contracts",
                className: "pairName",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: false,
            },
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: false,
            },
            {
                key: "quantity",
                text: "Qty",
                className: "quantity",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "quantity",
                text: "Filled Remaining",
                className: "Filled",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    var filledAmount = record.filledAmount ? record.filledAmount : 0;
                    var Remaining = parseFloat(record.quantity) - parseFloat(record.filledAmount);
                    return (
                        <Fragment>
                            {parseFloat(filledAmount).toFixed(8) + "/" + parseFloat(Remaining).toFixed(8)}
                        </Fragment>
                    );
                }
            },

            {
                key: "e_price",
                text: "Exec Price",
                className: "e_price",
                align: "left",
                sortable: false,
                width: 200,

            },
            {
                key: "price",
                text: "Order Price",
                className: "price",
                align: "left",
                sortable: false,
                width: 200,

            },
            {
                key: "buyorsell",
                text: "Buy or Sell",
                className: "price",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "orderValue",
                text: "Order Value",
                className: "orderValue",
                align: "left",
                sortable: false,
                width: 200,
            },
            {
                key: "orderType",
                text: "Type",
                className: "orderType",
                align: "left",
                sortable: false,
                width: 200,

            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                width: 200,


            }, {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: false,
                width: 200,


            },



        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'No Records found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.downloadCSV = this.downloadCSV.bind(this)
    }
    componentDidMount() {
        this.getData()
    };

    async pageChange(pageData) {
        try {
            // let userlist = []
            // if(pageData.filter_value.length > 0){
            //     let regx = new RegExp(pageData.filter_value, "i")
            //     let val = this.state.records.filter((val) => (val.email.match(regx)))
            //     userlist = val.reduce((unique, o) => {
            //         if (!unique.some(obj => obj === o.userId)) {
            //             unique.push(o.userId);
            //         }
            //         return unique;
            //     }, []);
            //     console.log(val, "pageData.filter_value", userlist)
            // }
            const data = {
                limit: pageData.page_size,
                page: pageData.page_number,
                search: pageData.filter_value,
                // userlist: userlist
            }
            
            this.setState({ limit: pageData.page_size,search: pageData.filter_value,page: pageData.page_number})
            const getData = await axios.post(url + "api/order_history/spot", data);
            this.setState({ records: getData.data.data, totalCount: getData.data.count });
            console.log("OnPageChange_OnPageChange", pageData);
        } catch (err) {
            console.log(err, "pageChange__err")
        }
    }


    getData() {
        const data = {
            limit:this.state.limit,
            page : this.state.page,
            search:this.state.search,
            // userlist:this.state.userlist
          }
        axios
            .post(url + "api/order_history/spot", data)
            .then(res => {
                console.log("order_history_order_history",res);
                this.setState({ records: res.data.data, totalCount:res.data.count })
            })
            .catch()
    }

    async sortByDate() {
        if (this.state.startDate != "" || this.state.endDate != "") {
            const formData = new FormData();
            formData.append("from", this.state.startDate);
            formData.append("to", this.state.endDate);
            formData.append("type", "search");
            console.log("formData--------",formData);
            const data = await axios.post(url + "api/orderHistory_DateFilter",formData);
            console.log("data_data",data.data);
            this.setState({ records: data.data.data })
        } else {
            console.log("Select date first");
        }
    }

    async downloadCSV() {
        let data = {
            export: 'csv',
            from: this.state.startDate,
            to: this.state.endDate,
        }
        const download = await OrderHistoryExportcsv(data);
        console.log("downloadCSV_downloadCSV", download);
    }

    render() {

        const { records } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Order History </h3>
                            <div className="mt-4">
                            <button onClick={this.downloadCSV} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                            {/* <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                            <button onClick={this.exportPDF} className='btn btn-rec mr-2 mb-2' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(XLS)</button> */}
                            </div>
                            <div>
                                <p className="filter_date_text">Filter based on date</p>
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ startDate: date}) }}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            maxDate={new Date()}
                                            onChange={(date) => { this.setState({ endDate: date});}}
                                            minDate={this.state.startDate}
                                            dateFormat="MMMM d, yyyy"
                                            className={classnames("form-control", {
                                            })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-2">
                                    <button className="btn btn-light mt_seact_date" onClick={()=> {this.sortByDate()}}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="resonsive_table">
                                              
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped order_history_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

tradehistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(tradehistory);
