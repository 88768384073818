import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addpair } from "../../actions/perpetualActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import keys from "../../actions/config";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
const url = keys.baseUrl;

const options = [{ 'value': "On", 'label': "On" }, { 'value': "Off", 'label': "Off" }];

class PairAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            email_assigned: false,
            first_currency: "",
            tickerroot: "",
            initial_margin: "",
            maint_margin: "",
            interest_base_symbol: "",
            interest_quote_symbol: "",
            funding_premium_symbol: "",
            risk_limit: "",
            risk_step: "",
            minpriceincrement: "",
            maxpriceincrement: "",
            mark_price: "",
            contract_size: "",
            maxquantity: "",
            minquantity: "",
            lotsize: "",
            funding_interval: "",
            second_currency: "",
            leverage: "",
            maker_rebate: "",
            dailyinterest: "",
            taker_fees: "",
            markuppercentage: "",
            floatingDigits: "",
            bot: "",
            maxPrice: "",
            minPrice: "",
            errors: {},
        };
        // console.log(this.state,'statessssssssss');
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }

    styles = {
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: "white",
            background: isFocused
                ? '#1d296a'
                : isSelected
                    ? '#113853'
                    : "#81c8f6",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            padding: '0 6px',
            backgroundColor: "#03081f",
            borderColor: '#81c8f6',
            borderRadius: 10,
            borderStyle: 'solid',
            borderWidth: '1px'

        }),
        control: (provided, state) => ({
            ...provided,
            height: '52px',
            borderRadius: 10,
            backgroundColor: "#03081f",
            border: 'none'

        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '52px',
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#fff'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };
    componentDidMount() {
        this.getData()
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            $("#add-spot-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        // console.log(nextProps);
        if (nextProps.auth !== undefined
            && nextProps.auth.perpetual !== undefined
            && nextProps.auth.perpetual.data !== undefined
            && nextProps.auth.perpetual.data.message !== undefined) {
            $('#add-spot-modal').modal('hide');
            toast(nextProps.auth.perpetual.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.perpetual = undefined;

        }
    }

    getData() {
        axios
            .get(url + "api/pair-data-first")
            .then(res => {

                console.log(res.data, 'datassss');
                var currencyarray = [];
                res.data.map((item, i) => {
                    const name = item.currencySymbol;
                    const value = item.currencySymbol;
                    const obj = { 'value': name, 'label': value };
                    currencyarray.push(obj);
                });
                // console.log(currencyarray,'currencyarray');
                this.setState({ first_currency1: currencyarray, email_assigned: true });
            })
            .catch()
        //  console.log(this.props,'authget1');
    }

    onChange = e => {
        console.log('onChange', e.target)
        let numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        const { id, value } = e.target
        console.log('onChange', id, value)
        if (["taker_fees", "markuppercentage", "maker_rebate", "mark_price", 'maxquantity', 'minquantity', 'maxPrice', 'minPrice', 'floatingDigits'].includes(id) && !numbers.test(value) && value !== "") {
            return false;
        }
        this.setState({ [id]: value });
    };
    handleChange = selectedOption => {
        this.setState({ first_currency: selectedOption.value });
        console.log(`Option selected:`, selectedOption);
    };
    handleChange1 = selectedOption => {
        this.setState({ second_currency: selectedOption.value });
        //console.log(`Option selected:`, selectedOption );
    };
    handlebot = selectedbot => {
        this.setState({ botselectPairAddModaled: selectedbot.value });
        //console.log(`Option selected:`, selectedOption );
    };


    onFaqAdd = async (e) => {
        // if(this.state.first_currency != "" && this.state.second_currency != ""){
        //     if (this.state.first_currency == this.state.second_currency) {
        // toast("First currency and second currency cannot be same", {
        //     position: toast.POSITION.TOP_CENTER,
        // })
        // return false;
        //     alert()
        //     let errors = {};
        //     errors["first_currency"] = "First currency and second currency cannot be same"
        //     this.setState({
        //         errors : errors
        //     })
        // }
        // }        
        // else {
        // try{
        //    const res = await axios.get(`https://data-api.binance.vision/api/v3/ticker/price?symbol=${this.state.first_currency + this.state.second_currency}`)
        // }catch(e){
        //     console.log("Error : ",e)
        //     const msg = e?.response?.data?.msg
        //     return toast.error(msg)
        // }
        e.preventDefault();
        const newcontract = {
            tickerroot: this.state.first_currency + this.state.second_currency,
            mark_price: this.state.mark_price,
            maxquantity: this.state.maxquantity,
            minquantity: this.state.minquantity,
            second_currency: this.state.second_currency,
            first_currency: this.state.first_currency,
            maker_rebate: this.state.maker_rebate,
            taker_fees: this.state.taker_fees,
            markuppercentage: this.state.markuppercentage,
            botstatus: this.state.botselectPairAddModaled,
            floatingDigits: this.state.floatingDigits,
            maxPrice: this.state.maxPrice,
            minPrice: this.state.minPrice
        };
        // console.log(newcontract);
        this.props.addpair(newcontract);
        this.setState({
            email_assigned: false,
            first_currency: "",
            // first_currency1: "",
            tickerroot: "",
            initial_margin: "",
            maint_margin: "",
            interest_base_symbol: "",
            interest_quote_symbol: "",
            funding_premium_symbol: "",
            risk_limit: "",
            risk_step: "",
            minpriceincrement: "",
            maxpriceincrement: "",
            mark_price: "",
            contract_size: "",
            maxquantity: "",
            minquantity: "",
            lotsize: "",
            funding_interval: "",
            second_currency: "",
            leverage: "",
            maker_rebate: "",
            dailyinterest: "",
            taker_fees: "",
            markuppercentage: "",
            floatingDigits: "",
            bot: "",
            maxPrice: "",
            minPrice: "",
            errors: {}
        })
        // }
    };

    emptyState() {
        this.setState({
            email_assigned: false,
            first_currency: "",
            // first_currency1: "",
            tickerroot: "",
            initial_margin: "",
            maint_margin: "",
            interest_base_symbol: "",
            interest_quote_symbol: "",
            funding_premium_symbol: "",
            risk_limit: "",
            risk_step: "",
            minpriceincrement: "",
            maxpriceincrement: "",
            mark_price: "",
            contract_size: "",
            maxquantity: "",
            minquantity: "",
            lotsize: "",
            funding_interval: "",
            second_currency: "",
            leverage: "",
            maker_rebate: "",
            dailyinterest: "",
            taker_fees: "",
            markuppercentage: "",
            floatingDigits: "",
            bot: "",
            maxPrice: "",
            minPrice: "",
            errors: {}
        })
    }

    render() {
        const { errors } = this.state;
        const { selectedOption } = this.state.first_currency;
        const { selectedOption1 } = this.state.second_currency;
        const { selectedbot } = this.state.bot

        //      const options1 = [
        //   { value: 'USD', label: 'USD' },
        //   { value: 'INR', label: 'INR' },
        // ];

        return (
            <div>
                <div className="modal fade" id="add-spot-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Pair</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => { this.emptyState() }}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqAdd} id="add-spot">
                                    <div className="row mt-2">

                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tickerroot}
                                                id="tickerroot"
                                                type="text"
                                                error={errors.tickerroot}
                                                className="d-none" />

                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="taker_fees">Taker Fees (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.taker_fees}
                                                error={errors.taker_fees}
                                                id="taker_fees"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.taker_fees
                                                })}
                                            />
                                            <span className="text-danger">{errors.taker_fees}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="markuppercentage"> Mark up Percentage</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.markuppercentage}
                                                error={errors.markuppercentage}
                                                id="markuppercentage"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.markuppercentage
                                                })}
                                            />
                                            <span className="text-danger">{errors.markuppercentage}</span>
                                        </div>
                                    </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maker_rebate">Maker Rebate (%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maker_rebate}
                                                error={errors.maker_rebate}
                                                id="maker_rebate"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maker_rebate
                                                })}
                                            />
                                            <span className="text-danger">{errors.maker_rebate}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="mark_price">Market Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.mark_price}
                                                error={errors.mark_price}
                                                id="mark_price"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.mark_price
                                                })}
                                            />
                                            <span className="text-danger">{errors.mark_price}</span>
                                        </div>
                                    </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxquantity">Max Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxquantity}
                                                error={errors.maxquantity}
                                                id="maxquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxquantity}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">Min Order Quantity</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minquantity}
                                                error={errors.minquantity}
                                                id="minquantity"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.minquantity}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxPrice">Max Price(%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxPrice}
                                                error={errors.maxPrice}
                                                id="maxPrice"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxPrice
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxPrice}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minPrice">Min Price(%)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minPrice}
                                                error={errors.minPrice}
                                                id="minPrice"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minPrice
                                                })}
                                            />
                                            <span className="text-danger">{errors.minPrice}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">Floating Digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.floatingDigits}
                                                error={errors.floatingDigits}
                                                id="floatingDigits"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.floatingDigits
                                                })}
                                            />
                                            <span className="text-danger">{errors.floatingDigits}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_currency">First Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.first_currency1}
                                                styles={this.styles} className="border_blue_select"
                                            />
                                            <span className="text-danger">{errors.first_currency}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="second_currency">Second Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={selectedOption1}
                                                onChange={this.handleChange1}
                                                options={this.state.first_currency1}
                                                styles={this.styles} className="border_blue_select"

                                            />
                                            <span className="text-danger">{errors.second_currency}</span>
                                        </div>
                                    </div>



                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="autobot">Binance Integration</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={selectedbot}
                                                onChange={this.handlebot}
                                                options={options}
                                                styles={this.styles} className="border_blue_select"

                                            />
                                            {/* <span className="text-danger">{errors.second_currency}</span> */}
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { this.emptyState() }}>Close</button>
                                <button
                                    //  form="add-spot"
                                    // type="submit"
                                    onClick={this.onFaqAdd}
                                    className="btn btn-primary">
                                    Add Contract
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PairAddModal.propTypes = {
    addpair: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addpair }
)(withRouter(PairAddModal));
