import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { newsletteremail } from "../../actions/userActions";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import {CKEditor} from 'ckeditor4-react'
import Multiselect from 'multiselect-dropdown-react';
const url = keys.baseUrl;
class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            email_assigned:false,
            email:{},
            message: "",
            errors: {},
        };
        console.log(this.state,'state');
         
        this.handleEditorChange = this.handleEditorChange.bind(this);
        }


        styles = {
            option: (styles, {isFocused, isSelected}) => ({
                ...styles,
                color: "white",
                background: isFocused
                    ? '#1d296a'
                    : isSelected
                        ? '#113853'
                        : "#81c8f6",
                zIndex: 1
            }),
            multiselectContainer: { 
                backgroundColor: "#03081f",
                borderColor: '#81c8f6',  
              }, 
              searchBox: { 
                border: 'none',
                minHeight: '50px',
                background: '#03081f'
              },     
              inputField: { 
                margin: '5px',
                background: '#03081f'
            },    
            chips: { 
                background: '#02071d'
              },    
            valueContainer: (provided, state) => ({
              ...provided,
              height: '52px',
              padding: '0 6px',
              backgroundColor: "#03081f",
              borderColor: '#81c8f6',
            borderRadius: 10,
            borderStyle: 'solid',
            borderWidth: '1px'
             
            }),
            optionContainer: { 
                border: '2px solid',
                background: '#03081f'
              },

            control: (provided, state) => ({
              ...provided,
              height: '52px',
              borderRadius:10,
              backgroundColor: "#03081f",
              border:'none'
             
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '52px',
              position: 'absolute',
              right: 0,
              top: 0,
              color:'#fff' 
            }),    
            groupHeading: 
            {
                backgroundColor: "#03081f",
                borderColor: '#81c8f6',
            },
            singleValue: (provided, state) => ({
              ...provided,
              color: "#fff"
            }),
            menuList: base => ({
                ...base,
                // kill the white space on first and last option
                padding: 0
              })
          };

        componentDidMount() {
            this.getData()  
        };

    
     componentWillReceiveProps(nextProps) {
         console.log(nextProps,'nextprops');
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.profile !== undefined
            && nextProps.auth.profile.data !== undefined
            && nextProps.auth.profile.data.message !== undefined) {
            toast(nextProps.auth.profile.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.profile = undefined;
        }
    }

    result=params=>{
        console.log(params);
     this.setState({
         email1: params
        });
        
       console.log(this.setState,'emailssss');   
    }

    onChange = e => {
      if (e.target.id === 'message') {
         this.setState({ message: e.target.value });
         };
       console.log(this.state,'statessss'); 
    }

    getData() {
        axios
        .get(url+"api/news-letter")
        .then(res => {   
            var arremail =[]; 
                res.data.map((item,i)=>{
                const name = item.email;
                const value = item.email;         
                const obj = {'name':name, 'value':value};
                arremail.push(obj);
                });
                console.log(arremail,'length');
                this.setState({email:arremail,email_assigned:true});
            })
        .catch()
        console.log(this.props,'authget1');
    }
 
    handleEditorChange(e) {
        console.log(e.editor.getData(),"message")
      this.setState({ "message": e.editor.getData()});
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
     }
    onemailsend =async e => {
        e.preventDefault();
        let errors = {};

        if((this.state.email1 == "" || this.state.email1 == undefined) || (this.state.message == "" || this.state.message == undefined) ){
            if(this.state.email1 == "" || this.state.email1 == undefined){
                errors["email"] = "Please Select Email"
            }
            console.log(this.state.message)
            if(this.state.message == "" || this.state.message == undefined){
                errors["message"] = "Please Select Message"
            }
            this.setState({errors:errors})
        }
        else{
            this.setState({errors :{}})
        const newsletteremail = {
            email: this.state.email1, 
            message: this.state.message
        };
        const data = new FormData();
        data.append('message', this.state.message);
        data.append('email', this.state.email1);
        
        this.props.newsletteremail(newsletteremail);
            await this.sleep(3000)
            window.location.reload()
        //console.log(newsletteremail,'newsletteremailzz');
        // axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data 
        // })
        // .then(res => { // then print response status
        //     console.log(res.statusText)
        // })
    }
    };

    render() {
        const { errors } = this.state;
        var email_try = this.state.email[0];

        if(typeof email_try == 'object'){}
        if(this.state.email_assigned){
            Object.keys(email_try).forEach(function(key) {
           
            });
        }
       
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Newsletter Details</h3>
                            <form noValidate onSubmit={this.onemailsend} id="send-email">
                                    
                                    <div className="row mt-5">
                                        <div className="col-md-3 email_align_left_news">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                           {this.state.email_assigned?
                                           <Multiselect options={this.state.email} onSelectOptions={this.result}  styles={this.styles}  className="border_blue_select" />
                                           :''
                                       }
                                               
                                               
                                             
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3 email_align_left_news">
                                            <label htmlFor="content">Message</label>
                                        </div>
                                        <div className="col-md-9">
                                        {/* <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue={this.state.message}
                                           id="message"
                                           value={this.state.message}  onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         /> */}
                                         <CKEditor
                                         config={{
                                            extraAllowedContent: 'div(*)',
                                            allowedContent: true,
                                            height: 500,
                                        }}
                                        initData={this.state.message}
                                        onChange={this.handleEditorChange}
                                         />
                                            <span className="text-danger">{errors.message}</span>
                                        </div>

                                    </div>
                                    
                                    
                                </form>
                                    <br />
                               <div className="modal-footer border-0">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="send-email"
                                    type="submit"
                                    className="btn btn-primary">
                                    Send
                                </button>
                            </div>
                         </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Newsletter.propTypes = {
    newsletteremail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { newsletteremail }
)(withRouter(Newsletter));
