import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    PASSWORD_UPDATE
} from "./types";
import keys from "./config";
const url = keys.baseUrl;
export const registerUser = (userData, history) => dispatch => {
    axios
        .post(url+"api/user-add", userData)
        .then(res => history.push("/login"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = userData => dispatch => {
    console.log("User data is " + JSON.stringify(userData));
    axios
        .post(url + "api/login", userData)
        .then(res => {

            console.log("reqsssssssssssssssssssss", res.data)
            const { token } = res.data;
            localStorage.setItem("admin", userData.email);
            localStorage.setItem("adminJwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            console.log("decoded_value", decoded);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            console.log(err, "Error------------");
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};
export const forgotUser = (forgotData,history) => dispatch => {
    

  axios
      .post(url+"api/forgot", forgotData)
      .then(res => {
        dispatch({
            type: FORGOT,
            payload:res.data.message
        })
        dispatch({
            type: GET_ERRORS,
            payload: ""
        })
      })
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
 };


 export const resetPassword = userData => dispatch => {
    axios
        .post(url+"api/resetPassword", userData)
        .then(res => {
            dispatch({
                type: PASSWORD_UPDATE,
                 payload: res.data.message
            })
            dispatch({
                type: GET_ERRORS,
                 payload: ""
            })
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                 payload: err.response.data
            })
        );
};
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem("adminJwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};
