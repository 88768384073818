import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import TemplateAddModal from "../partials/TemplateAddModal";
import TemplateUpdateModal from "../partials/TemplateUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;
class Emailtemplates extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "subject",
                text: "Subject",
                className: "subject",
                align: "left",
                sortable: false,
            },
            {
                key: "identifier",
                text: "Identifier",
                className: "identifier",
                align: "left",
                sortable: false
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-template-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                               >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Emailtemplates",
            no_data_text: 'No Email Templates found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            totalCount: 0,
            currentRecord: {
                id: '',
                subject: '',
                identifier: '',
                content: '',
            },
            limit :10,
            page : 1,
            searchState: ""

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        console.log(nextProps,'nextProps')
        this.getData()
    }

    getData() {
        const data = {
            limit :this.state.limit,
            page : this.state.page,
            search: this.state.searchState
          }
        axios
            .post(url+"api/template-data",data)
            .then(res => {
                this.setState({ records: res.data.data, totalCount : res.data.count})
            })
            .catch()
    }

    editRecord(record) {
         $("#update-template-modal").find(".text-danger").hide();
        this.setState({ currentRecord: record});
    }
    addRecord() {
        $("#add-template-modal").find(".text-danger").hide();
    }
    deleteRecord(record) {
      console.log(record);
        axios
            .post(url+"api/template-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        this.setState({"limit":pageData.page_size,"page":pageData.page_number, searchState: pageData.filter_value})
        const getData = await axios.post(url + "api/template-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                {console.log('edit',this.config)}
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <TemplateAddModal/>
                    {console.log('currentRecords',this.state)}
                    <TemplateUpdateModal record={this.state.currentRecord} onHide={()=>{this.setState({currentRecord:{}})}}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/*<button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-template-modal"><FontAwesomeIcon icon={faPlus}/> Add Template</button>*/}
                            <h3 className="mt-2 text-secondary">Email Templates List</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped email_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Emailtemplates.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Emailtemplates);
