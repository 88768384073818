import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PairAddModal from "../partials/PairAddModal";
import PairUpdateModal from "../partials/PairUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;

class Pairmanagement extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "tiker_root",
                text: "Ticker",
                className: "tiker_root",
                align: "left",
                sortable: false,
                width:200
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-spot-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                               >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Perpetual",
            no_data_text: 'No Contracts found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount : 0,
            limit: 10,
            page: 1,
            searchState: ""
        };

        this.state = {
            currentRecord: {
                id: '',
                tiker_root: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const data = {
            limit:this.state.limit,
            page : this.state.page,
            search: this.state.searchState
          }
        axios
            .post(url+"api/spotpair-data",data)
            .then(res => {
                console.log("res.data",res.data)
                if(res.data != undefined)
                {
                    this.setState({ records: res.data.data, totalCount: res.data.spotpairCount});
                }
            })
            .catch()
    }

    editRecord(record) {
         $("#update-spot-modal").find(".text-danger").hide();
         console.log(record)
        this.setState({ currentRecord: record});
    }
    addRecord() {
        $("#add-spot-modal").find(".text-danger").hide();
    }

    deleteRecord(record) {
        if(!window.confirm('Are you sure you want to delete this pair?')){ return false; }
        axios
            .post(url+"api/pair-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        setTimeout(
    function() {
        this.getData();
    }
    .bind(this),
    1000
);
        
    }

    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        this.setState({limit: pageData.page_size,page: pageData.page_number, searchState: pageData.filter_value})
        const getData = await axios.post(url + "api/spotpair-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.spotpairCount });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <PairAddModal/>
                    <PairUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-spot-modal"><FontAwesomeIcon icon={faPlus}/> Add Pair Page</button>
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary">Spot Trade Pairs</h3>
                            
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped pair_dt_table"
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Pairmanagement.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Pairmanagement);
