import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addFaq } from "../../actions/faqActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import jwt_decode from "jwt-decode";
import {CKEditor} from 'ckeditor4-react'
import {addAdmin} from '../../actions/superadminaction'
import 'react-toastify/dist/ReactToastify.css';
class AddAdminModel extends React.Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log("nextProps_admin", nextProps);
        if (nextProps.errors) {
             $("#add-faq-modal").find(".text-danger").show();
             toast(nextProps.errors.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        console.log(nextProps);
        if (nextProps.auth !== undefined
            && nextProps.auth.Superadmin !== undefined
            && nextProps.auth.Superadmin.data !== undefined
            && nextProps.auth.Superadmin.data.message !== undefined) {
                this.setState({email: "",password: "", errors: {}})
            $('#add-faq-modal').modal('hide');
            toast(nextProps.auth.Superadmin.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.Superadmin = undefined;
            
        }
    }
  
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

     onFaqAdd = async e => {
        e.preventDefault();
        const isValidParams = await this.validation()
        if(isValidParams){
            const newFaq = {
                email: this.state.email,
                password: this.state.password,
                superadmin : localStorage.getItem("admin")
            };
            console.log(newFaq);
            this.props.addAdmin(newFaq);
        }
      
    };

    async validation(){
        try {
            const {email, password} = this.state
            let error = {}
            if(password == "" || password == undefined || password == null){
                error.password = "Password Field is required"
            }
            if(email == "" || email == undefined || email == null){
                error.email = "Email Field is required"
            }else{
                let isValid = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
                if(!isValid){
                    error.email = "Incorrect email format"
                }
            }
            this.setState({errors: error})
            return Object.keys(error).length > 0 ? false : true
        } catch (e) {
            console.log("validation_err", e);
        }
    }

    render() {
        const { errors } = this.state;
        {  console.log("chech_Errr", errors)}
        return (
            <div>
                <div className="modal fade" id="add-faq-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Admin</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqAdd} id="add-faq">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="question">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                id="email"
                                                type="text"
                                                error={errors.email}
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}/>
                                            <p className="text-danger mx-0 mb-0 mt-2 d-block">{errors?.email}</p>
                                        </div>
                                    </div>
                                   

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="question">Password</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.password}
                                                id="password"
                                                type="text"
                                                error={errors.password}
                                                className={classnames("form-control", {
                                                    invalid: errors.password
                                                })}/>
                                            <p className="text-danger m-0 mb-0 mt-2 d-block">{errors?.password}</p>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-faq"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Admin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddAdminModel.propTypes = {
    addAdmin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addAdmin }
)(withRouter(AddAdminModel));
