import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { addarticle } from "../../actions/category";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import axios from "axios";
import keys from "../../actions/config";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const url = keys.baseUrl;

class ApproveAmlModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvemodal: true,
      riskValue:""
    };
  }

  onChange = (e)=>{
    try{
      console.log(e.target.value,e,"onselect",e.target.id)
    }catch(err){
      console.log(err,"onChange__err")
    }
  }

  render() {

    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="approve-aml-modal" data-reset="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">AML Customer Risk</h4>
                <button type="button" className="close" data-dismiss="modal" 
                    // onClick={()=>{document.getElementsByTagName("body")[0].classList.add("modal-open")}}
                >

                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Choose AML Customer Risk</p>
                <div className="radio_aml">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="aml_low" value="Low" checked={this.state.riskValue == 'Low' ? true:false} onChange={this.onChange.bind(this)}/>
                    <label class="form-check-label" for="aml_low">
                      Low
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="aml_medium" value="Medium" checked={this.state.riskValue == 'Medium' ? true:false}/>
                    <label class="form-check-label" for="aml_medium">
                      Medium
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="aml_high" value="High" checked={this.state.riskValue == 'High' ? true:false} />
                    <label class="form-check-label" for="aml_high">
                      High
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="aml_unaccepted" value="Unaccepted" checked={this.state.riskValue == 'Unaccepted' ? true:false} />
                    <label class="form-check-label" for="aml_unaccepted">
                      Unaccepted
                    </label>
                  </div>
                </div>

                {/* 
                <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="aml_low" name="aml_low" value={1} className="custom-control-input" 
                    />
                    <label className="custom-control-label" for="aml_low">Low</label>
                  </div>


                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="aml_low" name="aml_medium" value={2} className="custom-control-input" 
                    />
                    <label className="custom-control-label" for="aml_medium">Medium</label>
                  </div> */}
                <div className="text-right pb-4">
                  <button
                    type="button"
                    className="btn btn-secondary mr-3"
                    data-dismiss="modal"
                    // onClick={()=>alert(1)}
                    onClick={()=>{document.getElementsByTagName("body")[0].classList.add("modal-open")}}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    // data-dismiss="modal"
                    // onClick={()=>{document.getElementsByTagName("body")[0].classList.add("modal-open")}}

                  >
                    Submit
                  </button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  }
}



export default ApproveAmlModal;
