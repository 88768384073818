import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';

//import compents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import {perpetualTradeHistory} from '../../actions/reportActions'
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import keys from "../../actions/config"
const url = keys.baseUrl;
class BinancecommissionHistory extends Component {
    constructor(props) {
        super(props);
        console.log("prpos",props)
        this.columns = [          
            {
                key: "currecnysymbol",
                text: "Currency",
                className: "Status",
                align: "left",
                sortable: false,
                width: 200,
            },    
            {
                key: "tradetype",
                text: "Trade Type",
                className: "IPaddress",
                align: "left",
                sortable: false,
                width: 200,
            },
           
            {
                key: "fees",
                text: "Total Earned",
                className: "Browser",
                align: "left",
                sortable: false,
                width: 200,
                cell:records=>{
                    var fees = records?.fees?.toFixed(8)
                    return (
                        <Fragment>
                            {fees}
                        </Fragment>
                    )
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'No Records found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            totalCount:0,
            records: [],
            limit :10,
            page : 1
        };

    }
    getData(){
        let id = this.props.auth.user.id;
        const data = {
            limit :this.state.limit,
            page : this.state.page
          }
        axios.post(url+"api/binancecommissionhistory", data).then(res=>{
            console.log(res,"res")
            this.setState({records:res.data.data, totalCount: res.data.count})
        })
    }
    componentDidMount() {
        this.getData()
    };
    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        this.setState({"limit":pageData.page_size,"page":pageData.page_number})
        const getData = await axios.post(url + "api/binancecommissionhistory", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }
    render() {

        const { records,count } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Binance Commission History</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                onChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped login_hist_table" />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

BinancecommissionHistory.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  export default connect(
    mapStateToProps,
  )(withRouter(BinancecommissionHistory));

//export default (LoginHistory);
