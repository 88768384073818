import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Support extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            
            {
                key: "subject",
                text: "Subject",
                className: "name",
                align: "left",
                sortable: false,
            },
            {
                key: "replystatus",
                text: "Reply Status",
                className: "replystatus",
                align: "left",
                sortable: false
            },
            {
                key: "closedstatus",
                text: "Status",
                className: "closedstatus",
                align: "left",
                sortable: false
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            {record.closedstatus == "closed" ? "" :
                                <button
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => this.editRecord(record)}
                                >
                                    <i className="fa fa-reply"></i>
                                </button>
                            }
                            {/* <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                                >
                                <i className="fa fa-reply"></i>
                            </button> */}
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No Enquires found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id: '',
                email_add: '',
                subject: '',
                description: '',
                reply : '',
                attachment:''
            },
            limit :10,
            page : 1,
            searchState: ""
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        const data = {
            limit :this.state.limit,
            page : this.state.page,
            search: this.state.searchState
          }
        axios
            .post(url+"api/support-data", data)
            .then(res => {
                this.setState({ records: res.data.data , totalCount: res.data.count})
            })
            .catch()
            console.log(this.state,'zzsedrfvtguyvz€zayxhs');
    }

    editRecord(record) {
        console.log(record,'record');
         this.props.history.push("/support_reply/" + record._id);
         
    }

    deleteRecord(record) {
      console.log(record);
        axios
            .post(url+"api/support-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    async pageChange(pageData) {
        const data = {
            limit: pageData.page_size,
            page: pageData.page_number,
            search: pageData.filter_value
        }
        this.setState({"limit":pageData.page_size,"page":pageData.page_number,searchState: pageData.filter_value})
        const getData = await axios.post(url + "api/support-data", data);
        this.setState({ records: getData.data.data, totalCount: getData.data.count });

        console.log("OnPageChange_OnPageChange", pageData);
    }

    render() {
    
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Support Ticket</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped support_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Support.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Support);
