import React,{Fragment} from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import ReactDatatable from '@ashvin27/react-datatable';
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import keys from "../../actions/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import {momentFormat} from '../../lib/DateFormat'
import isEmpty from 'is-empty'

const url = keys.baseUrl;



class RecordViewModal extends React.Component {

    constructor(props) {
        super(props);
        console.log("RecordViewModal", props)
        this.state = {

        }

        this.columns = [
          
            {
                key: "sno",
                text: "S.No",
                className: "sno",
                align: "left",
                sortable: false,
                cell: (record, row) => {
                    return <div>{row + 1}</div>
                }
                
            },

            {
                key: "createdAt",
                text: "Date",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record,"record.statusTime")
                    let Time = record.createdAt
                    return (
                        <>
                         <p>
                            {momentFormat(Time,"DD-MM-YYYY HH:mm")}
                         </p>
                    </>
                     );
                }

            },
        
        
            {
                key: "Status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false
            },
            {
                key: "Comments",
                text: "Comments",
                className: "comments",
                align: "left",
                sortable: false
            },
          
          
        ];


     

        
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Users",
            no_data_text: 'No user found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search by email",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: false,
            show_pagination: false,
            show_info: false,
        };


        // this.getData = this.getData.bind(this);
 

     
        this.state = {
          
            recordviewmodal: false,

            records : [
                {
                    sno:"1",
                    date:"17 jan 2024",
                    status:"verified",
                    comments:"lorem ipsum dlar sit lorem ipsum dlar sit"
                },
                {
                    sno:"2",
                    date:"17 jan 2024",
                    status:"verified",
                    comments:"lorem ipsum dlar sit lorem ipsum dlar sit"
                },
                {
                    sno:"3",
                    date:"17 jan 2024",
                    status:"verified",
                    comments:"lorem ipsum dlar sit lorem ipsum dlar sit"
                }
            ]
         
        };
    }


    componentWillReceiveProps(nextProps) {
        console.log("nextProps",nextProps?.record?.history);
        this.setState({
            history : nextProps?.record?.history
        })
        // if (nextProps.record) {
            
        // }
    }

    render() {
        return (
            <div>
                {console.log("this.state.history", this.state.history)}
                <div className="modal fade" id="record-view-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                  View History

                                </h4>
                                <button type="button" className="close" data-dismiss="modal"
                                   >&times;</button>
                            </div>
                            <div className="modal-body">
                            <div className="resonsive_table table_insde_modal">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.history}
                                    columns={this.columns}
                                    responsive={true}
                                    dynamic={true}
                                   
                                />
                            </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}

RecordViewModal.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(RecordViewModal));
